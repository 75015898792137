<template>
  <div class="list js_activity_list">
    <Introduce :moduleId="4"/>
    <div class="list_box js_activity_list">
      <p class="new_activity">最新活动</p>
      <div class="new_box" v-if="firstActivity && Object.keys(firstActivity).length>0">
        <a :href="`/marketActivity/details?id=${firstActivity.moduleId}&moduleType=${modules.moduleType}`"
           target="_blank" class="box_link vishide animate__animated">
          <div class="new_img_box">
            <img class="new_img" :src="utils.setOssUrl(firstActivity.picture)" alt="">
          </div>
          <div class="new_content">
            <p class="new_date">{{ firstActivity.operateTime }}</p>
            <p class="new_title">
              {{ firstActivity.title }}
            </p>
            <p class="new_desc">
              {{ firstActivity.digest }}
            </p>
            <p class="new_detail">
              <dj-button type="dark">了解详情</dj-button>
            </p>
          </div>
        </a>
      </div>
      <Nothing v-else/>
      <p class="past_title">往期活动</p>
      <ul class="past_box" v-if="list.length>0">
        <li class="past_every" v-for="item in list" :key="item.moduleId">
          <a :href="`/marketActivity/details?id=${item.moduleId}&moduleType=${modules.moduleType}`" target="_blank"
             class="every_link vishide animate__animated">
            <div class="every_img_box">
              <img class="every_img" :src="utils.setOssUrl(item.picture)" alt="">
            </div>
            <div class="every_content">
              <p class="ev_con_title">{{ item.title }}</p>
              <p class="ev_con_desc">
                {{ item.digest }}
              </p>
              <p class="ev_con_date">{{ item.operateTime }}</p>
            </div>
            <p class="every_detail">
              <dj-button>查看详情</dj-button>
            </p>
          </a>
        </li>
      </ul>
      <Nothing v-else class="nothing"/>
    </div>
    <dj-paging :pageNumber="page.pageNo" :pageSize="page.pageSize" :total="page.total" :top="top"
               @current-change="handleCurrentChange"/>

  </div>
</template>

<script>
import {getCaseList} from "@/api/product.js";
import module from "@/const/module";
import $ from "jquery";
import utils from "@/utils/utils";
import metaInfoMixins from "@/utils/mixin/metaInfoMixins";

export default {
  mixins: [metaInfoMixins],
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 9,
        total: 0
      },
      list: [],
      firstActivity: {},
      modules: module.marketActivity,
      top: 0,
      utils
    };
  },
  created() {
    this.getCaseListFun();
  },
  mounted() {
    this.getListTop();
    this.$nextTick(() => {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
      window.onresize = () => {
        this.removeVishide();
      };
    });
  },
  methods: {
    getListTop() {
      this.$nextTick(() => {
        const el = $(".js_activity_list");
        let top = el.find(".past_title").offset().top;
        this.top = top;
        window.onresize = () => {
          let top = el.find(".past_title").offset().top;
          this.top = top;
        };
      });
    },
    handleCurrentChange(page) {
      this.page.pageNo = page;
      this.getCaseListFun();
    },
    getCaseListFun() {
      let params = {
        configType: this.modules.moduleType,
        ...this.page
      };
      getCaseList(params).then((res) => {
        if (res.success) {
          for (let i of res.data.caseList) {
            i.operateTime = utils.getActivityDate(i.operateTime);
          }
          if (res.data.firstActivity && Object.keys(res.data.firstActivity).length > 0) {
            res.data.firstActivity.operateTime = utils.getActivityDate(res.data.firstActivity.operateTime);
          }
          this.list = res.data.caseList;
          if (res.data.count > 0) {
            res.data.count--;
          }
          this.page.total = res.data.count;
          this.firstActivity = res.data.firstActivity;
          this.$nextTick(() => {
            this.handleScroll();
            this.removeVishide();
          });
        }
      });
    },
    handleScroll() {
      utils.handleScroll($(".js_activity_list .new_box"), (i, el) => {
        el.children(".box_link").removeClass("vishide").addClass("animate__slideInUp").removeClass("animate__slideOutDown");
      }, (i, el) => {
        el.children(".box_link").removeClass("animate__slideInUp").addClass("animate__slideOutDown");
      });

      utils.handleScroll($(".js_activity_list .past_every"), (i, el) => {
        el.children(".every_link").removeClass("vishide").addClass("animate__slideInUp").removeClass("animate__slideOutDown");
      }, (i, el) => {
        el.children(".every_link").removeClass("animate__slideInUp").addClass("animate__slideOutDown");
      });
    },
    removeVishide() {
      let w = $(window).width();
      if (w < 960) {
        $(".js_activity_list .box_link,.js_activity_list .every_link").removeClass("vishide");
      } else {
        $(".js_activity_list .box_link,.js_activity_list .every_link").addClass("vishide");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.list {
  .list_box {
    padding: 0 60px;
    .new_activity {
      font-size: 40px;
      color: #333333;
      line-height: 56px;
      text-align: center;
      margin: 60px 0 40px 0;
      font-weight: bold;
    }
    .new_box {
      border-radius: 4px;
      .box_link {
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }
      .new_img {
        width: 100%;
        height: 353px;
      }
      .new_content {
        padding: 0 24px;
        .new_date {
          margin-top: 20px;
          font-size: 20px;
          color: #666666;
          line-height: 28px;
        }
        .new_title {
          margin-top: 12px;
          font-size: 26px;
          color: #333333;
          line-height: 37px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .new_desc {
          margin-top: 12px;
          font-size: 23px;
          color: #666666;
          line-height: 32px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .new_detail {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 24px 0 32px 0;
          /deep/ a {
            width: 188px;
            height: 64px;
            font-size: 22px;
          }
        }
      }
    }
    .past_title {
      font-size: 40px;
      color: #333333;
      line-height: 56px;
      margin-top: 60px;
      text-align: center;
      font-weight: bold;
    }
    .past_box {
      margin-top: 40px;
      .past_every {
        margin-top: 32px;
        &:first-child {
          margin-top: 0;
        }
        .every_link {
          display: flex;
        }
        .every_img {
          width: 267px;
          height: 150px;
        }
        .every_content {
          margin-left: 16px;
          flex: 1;
          min-width: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .ev_con_title {
            font-size: 26px;
            color: #333333;
            line-height: 37px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .ev_con_desc {
            font-size: 23px;
            color: #666666;
            line-height: 32px;
            margin-top: 8px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            height: 64px;
          }
          .ev_con_date {
            font-size: 20px;
            color: #666666;
            line-height: 28px;
            margin-top: 13px;
          }
        }
        .every_detail {
          display: none;
        }
      }
    }
    .nothing {
      padding: 40px 0 60px 0;
    }
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .list {
    .list_box {
      padding: 0 60px;
      .new_activity {
        font-size: 32px;
        color: #333333;
        line-height: 45px;
        text-align: center;
        margin: 60px 0 36px 0;
        font-weight: bold;
      }
      .new_box {
        border-radius: 4px;
        .box_link {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 100%;
        }
        .new_img {
          width: 552px;
          height: 310px;
        }
        .new_content {
          padding: 0 15px 0 24px;
          min-width: 0;
          flex: auto;
          .new_date {
            margin-top: 17px;
            font-size: 16px;
            color: #666666;
            line-height: 22px;
          }
          .new_title {
            margin-top: 10px;
            font-size: 18px;
            color: #333333;
            line-height: 25px;
            height: 50px;
            text-overflow: ellipsis;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .new_desc {
            margin-top: 16px;
            font-size: 16px;
            color: #666666;
            line-height: 22px;
            height: 88px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
          }
          .new_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 35px 0 22px 0;
            /deep/ a {
              width: 223px;
              height: 52px;
              font-size: 18px;
            }
          }
        }
      }
      .past_title {
        font-size: 32px;
        color: #333333;
        line-height: 45px;
        margin-top: 60px;
        text-align: center;
        font-weight: bold;
      }
      .past_box {
        margin-top: 40px;
        .past_every {
          margin-top: 16px;
          &:first-child {
            margin-top: 0;
          }
          .every_link {
            display: flex;
            background-color: #FFFFFF;
          }
          .every_img {
            width: 264px;
            height: 148px;
          }
          .every_content {
            margin-left: 24px;
            margin-right: 20px;
            flex: 1;
            min-width: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .ev_con_title {
              font-size: 18px;
              color: #333333;
              line-height: 25px;
              margin-top: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .ev_con_desc {
              font-size: 16px;
              color: #666666;
              line-height: 22px;
              margin-top: 8px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              height: 44px;
            }
            .ev_con_date {
              font-size: 16px;
              color: #666666;
              line-height: 22px;
              margin-top: 17px;
            }
          }
          .every_detail {
            display: none;
          }
        }
      }
      .nothing {
        padding: 40px 0 60px 0;
      }
    }
  }
}
@media (min-width: 961px) {
  .list {
    .list_box {
      padding: 0;
      width: 1200px;
      margin: 0 auto;
      .new_activity {
        font-size: 32px;
        line-height: 45px;
      }
      .new_box {
        border-radius: 0;
        height: 446px;
        display: flex;
        flex-direction: row;
        .box_link {
          display: flex;
          flex-direction: row;
          &:hover {
            box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
            .new_img {
              transform: scale(1.1);
            }
            .new_content {
              .new_title {
                color: #0064EB;
              }
            }
          }
        }
        .new_img_box {
          overflow: hidden;
        }
        .new_img {
          width: 794px;
          height: 446px;
          transition: transform .5s;
        }
        .new_content {
          min-width: 0;
          flex: 1;
          padding: 0 24px;
          .new_date {
            margin-top: 38px;
            font-size: 14px;
            color: #999999;
            line-height: 20px;
          }
          .new_title {
            margin-top: 12px;
            font-size: 20px;
            color: #333333;
            line-height: 30px;
            white-space: normal;
            text-overflow: clip;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            height: 60px;
          }
          .new_desc {
            margin-top: 32px;
            font-size: 16px;
            color: #999999;
            line-height: 25px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
            height: 125px;
          }
          .new_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 79px 0 25px 0;
            /deep/ div {
              width: 100%;
            }
            /deep/ a {
              width: 100%;
              height: 57px;
              font-size: 18px;
            }
          }
        }
      }
      .past_title {
        font-size: 32px;
        line-height: 45px;
      }
      .past_box {
        margin-top: 40px;
        .past_every {
          margin-top: 24px;
          &:first-child {
            margin-top: 0;
          }
          .every_link {
            background-color: #FFFFFF;
            display: flex;
            &:hover {
              box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
              .every_img {
                transform: scale(1.1);
              }
              .every_content {
                .ev_con_title {
                  color: #0064EB;
                }
              }
            }
          }
          .every_img_box {
            overflow: hidden;
          }
          .every_img {
            width: 282px;
            height: 158px;
            transition: transform .5s;
          }
          .every_content {
            width: 588px;
            margin-left: 24px;
            flex: auto;
            flex-grow: 0;
            flex-shrink: 0;
            padding: 24px 0 14px 0;
            .ev_con_title {
              font-size: 20px;
              line-height: 28px;
            }
            .ev_con_desc {
              font-size: 16px;
              line-height: 22px;
              margin-top: 12px;
              height: 44px;
            }
            .ev_con_date {
              font-size: 12px;
              line-height: 17px;
              margin-top: 22px;
            }
          }
          .every_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 24px;
            margin-left: auto;
            /deep/ a {
              width: 161px;
              height: 50px;
            }
          }
        }
      }
    }
  }
}
</style>
